@import '../../assets/index.scss';

.navbar {
  width: 100%;
  color: $bone-white;
  position: fixed;
  top: 0;
  z-index: 5;
  overflow: hidden;

  .navbar-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: right;
    justify-content: right;

    .icon {
      font-size: 30px;
    }
  }

  .logo-container {
    height: 35px;
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .logo-container:hover img {
    cursor: pointer;
  }

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;

    .left {
      font-size: 16px;
      padding-left: 10px;
    }

    .right {
      font-size: 16px;
      margin-right: 50px;

      a {
        padding: 10px 20px;
      }
    }
  }
}

.drawerButton {
  color: $bone-white;
}

