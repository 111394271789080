.app {
  .sections {
    width: 100%;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }

    > *{
      width: 100vw;
      margin-bottom: 50px;
    }
  }
}