@import '../../assets/index.scss';

.footer {
  margin-top: 80px;
  bottom: 0;
  background-color: $darker-blue;
  height: 30px;
  line-height: 30px;
  text-align: center;
  align-items: center;
  font-size: 14px;

  h5 {
    margin: 15px 0 0 0;
    color: $bone-white;
    font-weight: lighter;
    font-size: 14px;
  }
}