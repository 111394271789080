@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

$inter: 'Inter', sans-serif;

h1, h4, h5 {
  font-family: $inter;
  font-weight: medium;
}

h2, h3 {
  font-family: $inter;
  font-weight: medium;
  color: $bone-white;
}

h6, p {
  font-family: $inter;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $bone-white;
  font-family: $inter;
  font-weight: lighter;
}