@import '../../assets/index.scss';

#contact {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.contact {
  .wrapper {
    padding-top: 10px;
  }
}