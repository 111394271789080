@import '../../assets/index.scss';

#about {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  margin-top: 70px;
  padding: 5px;
}

.about {
  padding: 0 8px;

  p {
    color: $dark-slate;
    white-space: normal;

    span {
      color: $aquamarine;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;

    .row-below {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        padding: 0 2px;
        width: calc(15% - 8px);
        height: 50px;

        h5 {
          color: $slate;
          font-weight: lighter;
          font-size: 14px;
          margin-right: 8px;
        }

        img {
          padding: 2px;
          width: 27px;
        }
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      width: 180px;
      height: 50px;

      h4 {
        color: $slate;
        font-weight: lighter;
        font-size: 20px;
        margin-right: 10px;
      }

      h5 {
        color: $slate;
        font-weight: lighter;
        font-size: 14px;
        margin-right: 8px;
      }

      img {
        padding: 2px;
        width: 40px;
      }
    }

    .item-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      height: 50px;

      h4 {
        color: $slate;
        font-weight: lighter;
        font-size: 18px;
        margin-right: 10px;
      }

      h5 {
        color: $slate;
        font-weight: lighter;
        font-size: 12px;
        margin-right: 8px;
      }

      img {
        padding: 2px;
        width: 30px;
      }
    
    }
  }
}

.intro {
  width: 100%;
  flex: 0.5;

  .wrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    h1 {
      color: $bone-white;
      margin: 0px;
    }
  
    h3 {
      color: $slate;
      margin: 0px;
    }
  
    h5 {
      color: $aquamarine;
      font-weight: lighter;
      margin-bottom: 5px;
    }
  }
}